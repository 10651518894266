"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    name: "App",
    setup: function () {
        return {
            message: "Hello Vue!",
        };
    },
};
