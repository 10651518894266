<template>
  <div class="container px-30 pb-10 m-auto <md:px-10 <md:pb-5 <md:pt-10">
    <div class="w-120  lg:mx-40 lg:mx-50 xl:mx-64 2xl:mx-72">
        <h2 class="text-2xl font-bold mt-5 mb-3">帮助📭</h2>
        <div class="ml-5"></div>
        <label class="font-bold mt-5 mb-2 block">主要功能</label>
        <div class="content ml-4">
          <ol>
            <li>支持微信、邮箱登录✉</li>
            <li>支持上传图片📌、邮箱提醒😝</li>
            <li>可以匿名🥰、评论📝、搜索🪧等</li>
          </ol>
        </div>
        <label class="font-bold mt-5 mb-2 block">区分男生女生</label>
        <div class="content ml-4">
          <span class="fas fa-mars m-2" style="color: #3b6fa8">男生</span>
          <span class="fas fa-venus m-2" style="color: deeppink">女生</span>
          <span class="fas fa-genderless m-2">保密</span>
        </div>
        <label class="font-bold mt-5 mb-2 block">发布表白</label>
        <div class="content ml-4">
          <p>可以设置匿名表白</p>
          <p>支持评论邮箱提醒（需先绑定邮箱）</p>
          <p>最多可以上传9张图片</p>
          <p>支持男男、男女、女男、女女</p>
        </div>
        <label class="font-bold mt-5 mb-2 block">发布评论</label>
        <div class="content ml-4">
          <p>最多可以上传6张图片</p>
          <p>不可匿名评论</p>
        </div>
        <label class="font-bold mt-5 mb-2 block">搜索帖子</label>
        <div class="content ml-4">
          <p>能同时搜索用户名称、匿名名称、表白内容、表白者名称</p>
          <p>暂不支持搜索评论</p>
        </div>
        <label class="font-bold mt-5 mb-2 block">点赞</label>
        <div class="content ml-4">
          <p>每个帖子每个账号只能点一次赞</p>
          <p>可以取消点赞</p>
          <p>以后会开发对赞多的帖子特殊展示</p>
        </div>
    
        <h2 class="text-2xl font-bold mt-5 mb-3">项目完善中，敬请期待⭐⭐⭐</h2>
        <div class="ml-5">
          <a
            href="https://github.com/star-love-letter"
            class="text-center block w-20 h-25"
            target="_blank"
          >
            <img class="w-20" src="../assets/img/github_logo.jpeg" alt="GitHub" />
            <span>GitHub</span>
          </a>
        </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>