<template>
  <div class="emoji-Bigbox">
    <el-scrollbar ref="emojiScroll" class="emojiScroll">
      <div
        class="emoji-box"
        v-for="(item, index) in emoji_icon"
        :key="index"
        @click="pickEmoji(index)"
      >
        {{ item }}
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
export default {
  setup(props,context) {
    const emoji_icon = [
      "😀",
      "😃",
      "😄",
      "😁",
      "😆",
      "😅",
      "🤣",
      "😂",
      "🙂",
      "🙃",
      "😉",
      "😊",
      "😇",
      "😍",
      "🤩",
      "😘",
      "😗",
      "😚",
      "😙",
      "😋",
      "😛",
      "😜",
      "🤪",
      "😝",
      "🤑",
      "🤗",
      "🤭",
      "🤫",
      "🤔",
      "🤐",
      "🤨",
      "😐",
      "😑",
      "😶",
      "😏",
      "😒",
      "🙄",
      "😬",
      "🤥",
      "😌",
      "😔",
      "😪",
      "🤤",
      "😴",
      "😷",
      "🤒",
      "🤕",
      "🤢",
      "🤮",
      "🤧",
      "😵",
      "🤯",
      "🤠",
      "😎",
      "🤓",
      "🧐",
      "😕",
      "😟",
      "🙁",
      "😮",
      "😯",
      "😲",
      "😳",
      "😦",
      "😧",
      "😨",
      "😰",
      "😥",
      "😢",
      "😭",
      "😱",
      "😖",
      "😣",
      "😞",
      "😓",
      "😩",
      "😫",
      "😤",
      "😡",
      "😠",
      "🤬",
      "😈",
      "👿",
      "💀",
      "💩",
      "🤡",
      "👹",
      "👺",
      "👻",
      "👽",
      "👾",
      "🤖",
      "😺",
      "😸",
      "😹",
      "😻",
      "😼",
      "😽",
      "🙀",
      "😿",
      "😾",
      "💋",
      "👋",
      "🤚",
      "🖐",
      "✋",
      "🖖",
      "👌",
      "🤞",
      "🤟",
      "🤘",
      "🤙",
      "👈",
      "👉",
      "👆",
      "🖕",
      "👇",
      "👍",
      "👎",
      "✊",
      "👊",
      "🤛",
      "🤜",
      "👏",
      "🙌",
      "👐",
      "🤲",
      "🤝",
      "🙏",
      "💅",
      "🤳",
      "💪",
      "👂",
      "👃",
      "🧠",
      "👀",
      "👁",
      "👅",
      "👄",
      "👶",
      "🧒",
      "👦",
      "👧",
      "🧑",
      "👱",
      "👨",
      "🧔",
      "👱‍",
      "👨‍",
      "👨‍",
      "👨‍",
      "👨‍",
      "👩",
      "👱‍",
      "👩‍",
      "👩‍",
      "👩‍",
      "👩‍",
      "🧓",
      "👴",
      "👵",
      "🙍",
      "🙍‍",
      "🙍‍",
      "🙎",
      "🙎‍",
      "🙎‍",
      "🙅",
      "🙅‍",
      "🙅‍",
      "🙆",
      "🙆‍",
      "🙆‍",
      "💁",
      "💁‍",
      "💁‍",
      "🙋",
      "🙋‍",
      "🙋‍",
      "🙇",
      "🙇‍",
      "🙇‍",
      "🤦",
      "🤦‍",
      "🤦‍",
      "🤷",
      "🤷‍",
      "🤷‍",
      "👨‍⚕️",
      "👩‍⚕️",
      "👨‍🎓",
      "👩‍🎓",
      "👨‍🏫",
      "👩‍🏫",
      "👨‍⚖️",
      "👩‍⚖️",
      "👨‍🌾",
      "👩‍🌾",
      "👨‍🍳",
      "👩‍🍳",
      "👨‍🔧",
      "👩‍🔧",
      "👨‍🏭",
      "👩‍🏭",
      "👨‍💼",
      "👩‍💼",
      "👨‍🔬",
      "👩‍🔬",
      "👨‍💻",
      "👩‍💻",
      "👨‍🎤",
      "👩‍🎤",
      "👨‍🎨",
      "👩‍🎨",
      "👨‍✈️",
      "👩‍✈️",
      "👨‍🚀",
      "👩‍🚀",
      "👨‍🚒",
      "👩‍🚒",
      "👮",
      "👮‍♂️",
      "👮‍♀️",
      "🕵",
      "🕵️‍♂️",
      "🕵️‍♀️",
      "💂",
      "💂‍",
      "💂‍",
      "👷",
      "👷‍",
      "👷‍",
      "🤴",
      "👸",
      "👳",
      "👳‍",
      "👳‍",
      "👲",
      "🧕",
      "🤵",
      "👰",
      "🤰",
      "🤱",
      "👼",
      "🎅",
      "🤶",
      "🧙",
      "🧙‍",
      "🧙‍",
      "🧚",
      "🧚‍",
      "🧚‍",
      "🧛",
      "🧛‍",
      "🧛‍",
      "🧜",
      "🧜‍",
      "🧜‍",
      "🧝",
      "🧝‍",
      "🧝‍",
      "🧞",
      "🧞‍",
      "🧞‍",
      "🧟",
      "🧟‍",
      "🧟‍",
      "💆",
      "💆‍",
      "💆‍",
      "💇",
      "💇‍",
      "💇‍",
      "🚶",
      "🚶‍",
      "🚶‍",
      "🏃",
      "🏃‍",
      "🏃‍",
      "💃",
      "🕺",
      "🕴",
      "👯",
      "👯‍",
      "👯‍",
      "🧖",
      "🧖‍",
      "🧖‍",
      "🧘",
      "👭",
      "👫",
      "👬",
      "💏",
      "👨‍",
      "👩‍",
      "💑",
      "👨‍",
      "👩‍",
      "👪",
      "👨‍👩‍👦",
      "👨‍👩‍👧",
      "👨‍👩‍👧‍👦",
      "👨‍👩‍👦‍👦",
      "👨‍👩‍👧‍👧",
      "👨‍👨‍👦",
      "👨‍👨‍👧",
      "👨‍👨‍👧‍👦",
      "👨‍👨‍👦‍👦",
      "👨‍👨‍👧‍👧",
      "👩‍👩‍👦",
      "👩‍👩‍👧",
      "👩‍👩‍👧‍👦",
      "👩‍👩‍👦‍👦",
      "👩‍👩‍👧‍👧",
      "👨‍👦",
      "👨‍👦‍👦",
      "👨‍👧",
      "👨‍👧‍👦",
      "👨‍👧‍👧",
      "👩‍👦",
      "👩‍👦‍👦",
      "👩‍👧",
      "👩‍👧‍👦",
      "👩‍👧‍👧",
      "🗣",
      "👤",
      "👥",
      "👣",
      "🌂",
      "☂",
      "👓",
      "🕶",
      "👔",
      "👕",
      "👖",
      "🧣",
      "🧤",
      "🧥",
      "🧦",
      "👗",
      "👘",
      "👙",
      "👚",
      "👛",
      "👜",
      "👝",
      "🎒",
      "👞",
      "👟",
      "👠",
      "👡",
      "👢",
      "👑",
      "👒",
      "🎩",
      "🎓",
      "🧢",
      "⛑",
      "💄",
      "💍",
      "💼",
    ];
    function pickEmoji(idx) {
      return context.emit("selectEmoji", emoji_icon[idx]);
    }
    return{
        emoji_icon,
        pickEmoji
    }
  },
};
</script>
<style lang="less" scoped>
.emoji-Bigbox {
  height: 200px;
  background: #fff;
  border-radius: 10px;
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  // overflow-y: scroll;
  .el-scrollbar {
    .el-scrollbar__wrap /deep/ {
      overflow-y: hidden !important;
    }
  }
  .emojiScroll {
    height: 100%;
  }
  .emoji-box {
    display: inline-block;
    width: 30px;
    height: 30px;
    font-size: 22px;
    // border: 1px solid red;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    // &:hover {
    //   border-radius: 5px;
    //   // background: rgba(160, 158, 158, 0.39);
    //   transform: scale(1.5);
    // }
  }
}
</style>
